<template>
  <div class="about-area pt-120 pb-90" style="background-color: white;">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="ab-box pl-50 mb-30">
            <div class="sec-wrapper">
              <h5>About Us</h5>
              <h2 class="section-title">Chapter 375</h2>
              <p v-for="data in about.attributes.about" :key="data.id">
                {{ data.children[0].text }}
              </p>
            </div>

            <div class="ab-btn">
              <a href="#pricing" class="tp-btn">Donate</a>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-7 col-lg-6">
          <div class="abs-images abs-images-2 pl-50">
            <div class="row">
              <div class="col-7">
                <div class="abs-img  mb-30">
                  <img src="../../assets/img/slider/rotc2.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue'

const initialized = ref(false)
const about = ref({ attributes: { about: [] } })

const props = defineProps({
  about: {
    type: Array,
    required: true
  }
})

watch(() => props.about, (newPosts) => {
  if (!initialized.value) {
    about.value.attributes.about = newPosts[0].attributes.about
    initialized.value = true
  }
}, { deep: true })
</script>
