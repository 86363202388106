<template>
  <section class="pricing__area pt-100 pb-110" id="pricing">
    <div class="container">
      <div class="row mb-60">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>Donate</h5>
            <h2 class="section-title">Donate to Chapter 375</h2>
          </div>
        </div>
      </div>
      <div class="row" id="pricing2">
        <div v-for="pricing in pricingData" :key="pricing.id" class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
          <div :class="pricing.activeClass
            ? `pricing__item text-center transition-3 mb-30 ${pricing.activeClass}`
            : 'pricing__item text-center transition-3 mb-30'
          ">
            <div class="pricing__header mb-25">
              <h3>{{ pricing.title }}</h3>
              <!-- <p>Support Americas combat wounded vets</p> -->
              
            </div>
            <div class="pricing__tag d-flex align-items-start justify-content-center mb-30">
              <span>$</span>
              <h4>{{ pricing.price }}</h4>
            </div>
            <div class="pricing__buy mb-20" id="schedule">
              <a  href="#schedule" class="tp-btn w-100">
                <span></span> Donate</a>
            </div>
            <div class="pricing__features text-start">
              <ul>
                <li>Donations are tax deductible</li>
                <li>Support local combat wounded veterans and their families</li>
                <li>Support local outreach and charity conducted by Chapter 375</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isVisible">
      <iframe src="https://koalendar.com/e/1-hour-tutoring?embed=true" width="100%" style="height: 632px !important"
        frameborder="0"></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "PricingArea",
  data() {
    return {
      isVisible: false,
      isDesktop: false,
      pricingData: [
        {
          id: "1",
          title: "Donation",
          price: "5",
          activeClass: "active",
        },
        {
          id: "2",
          title: "Donation",
          price: "20",
          activeClass: "active",
        },
        {
          id: "3",
          title: "Donation",
          price: "50",
          activeClass: "active",
        },
        {
          id: "4",
          title: "Donation",
          price: "Custom",
          activeClass: "active",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", this.checkDeviceType);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
  methods: {
    toggleVisibility() {
      const isDesktop = this.checkDeviceType();

      if (isDesktop) this.isVisible = !this.isVisible;
      else window.open('https://koalendar.com/e/1-hour-tutoring')
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent;
      this.isDesktop = !/Mobi|Android/i.test(userAgent);

      return this.isDesktop;
    },
  },
};
</script>