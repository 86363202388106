<template>
   <section class="team__details pt-120 pb-160" v-if="usedMember">
      <div class="container">
         <div class="team__details-inner p-relative white-bg">
            <div class="row">
               <div class="col-xl-6 col-lg-6">
                  <div class="team__details-img w-img mr-70">
                     <img :src="getUrl(usedMember.attributes.image.data.attributes.url)" alt="" />
                  </div>
               </div>
               <div class="col-xl-6 col-lg-6">
                  <div class="team__details-content pt-75">
                     <span>{{ usedMember.attributes.title }}</span>
                     <h3>{{ usedMember.attributes.name }}</h3>
                     <h6>{{ usedMember.attributes.conflict }}</h6>
                     <div style="display: flex; flex-direction: row;">
                        <img v-for="unit in units" :key="unit.hash" :id="unit.id"
                           style="height: 30px; margin-right: 10px;"
                           :src="getUrl(unit.attributes.formats.thumbnail.url)" alt="" />
                     </div>
                     <br>
                     <div class="team__details-contact">
                        <ul>
                           <li>
                              <div class="icon theme-color ">
                                 <i class="fal fa-envelope"></i>
                              </div>
                              <div class="text theme-color ">
                                 <span><a>{{ usedMember.attributes.email }}</a></span>
                              </div>
                           </li>
                           <li>
                              <div class="icon theme-color">
                                 <i class="fas fa-phone-volume"></i>
                              </div>
                              <div class="text theme-color">
                                 <span><a>{{ usedMember.attributes.phoneNumber }}</a></span>
                              </div>
                           </li>
                           <li>
                              <div class="icon">
                                 <i class="fal fa-map-marker-alt"></i>
                              </div>
                              <div class="text">
                                 <a target="_blank" href="https://maps.app.goo.gl/7udqqzxCAQtGQqbL6">{{
                                    usedMember.attributes.address }}</a>
                              </div>
                           </li>
                        </ul>
                     </div>
                     <div style="">
                        <div class="team__details-social theme-social wow fadeInUp">
                           <ul>
                              <li>
                                 <a href="https://www.linkedin.com/in/cole-stasney/" target="_blank">
                                    <i class="fab fa-linkedin"></i>
                                 </a>
                                 <a target="_blank"><i class="fab fa-facebook-f"></i></a>
                                 <a target="_blank"><i class="fab fa-twitter"></i></a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-xl-10 offset-xl-1">
               <div class="team__details-info mt-60">
                  <h4>About</h4>
                  <p v-for="(text, index) in usedMember.attributes.biography" :key="index">
                     {{ text.children[0].text }}
                  </p>
               </div>
            </div>
         </div>
         <br>
         <br>
         <br>
         <div class="row mb-40 z-index" v-show="gallery">
            <div class="col-12">
               <div class="sec-wrapper text-center">
                  <h2 class="section-title">Gallery</h2>
               </div>
            </div>
         </div>
         <div class="test-active swiper-container">
            <swiper ref="mySwiper" class="swiper-wrapper pb-70" :space-between="5" :slides-per-view="3"
               :modules="modules" :pagination="{ clickable: true }" :loop="true" :breakpoints="breakpoints">
               <swiper-slide v-for="photo in gallery" :key="photo.hash">
                  <div class="testi-item swiper-slide">
                     <div class="">
                        <img :id="photo.hash" :src="getUrl(photo.attributes.formats.small.url)" alt="" />
                     </div>
                  </div>
               </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
         </div>
      </div>
   </section>
   <div v-else class="loading-state">
      <div class="text-center">
         <h3>Loading team member details...</h3>
      </div>
   </div>
</template>

<script setup>
import axios from 'axios'
import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination } from "swiper"
import { useRoute } from 'vue-router'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const memberId = route.params.id

const reverseNormalizeUrl = (name) => name.replace(/-/g, ' ')

const modules = [Pagination]
const teamData = ref(null)
const isLoading = ref(true)

const isProduction = process.env.VUE_APP_environment === 'production'
const urlBaseUploads = isProduction ? process.env.VUE_APP_strapiServerProd : process.env.VUE_APP_strapiServerLocal
const urlBase = isProduction ? process.env.VUE_APP_strapiServerProdApi : process.env.VUE_APP_strapiServerLocalApi

const fetchDirectData = async () => {
   try {
      const response = await axios.get(`${urlBase}team-leaderships?populate=*`)
      teamData.value = response.data.data
   } catch (error) {
      console.error('Error fetching team data:', error)
   } finally {
      isLoading.value = false
   }
}

const usedMember = computed(() => {
   const storeTeam = store.state.team
   if (storeTeam && storeTeam.length > 0) {
      return storeTeam.find(mem => mem.attributes.name.toLowerCase() === reverseNormalizeUrl(memberId))
   } else if (teamData.value) {
      return teamData.value.find(mem => mem.attributes.name.toLowerCase() === reverseNormalizeUrl(memberId))
   }
   return null
})

const gallery = computed(() => usedMember.value?.attributes.gallery.data || [])
const units = computed(() => usedMember.value?.attributes.units.data || [])

const breakpoints = {
   0: { slidesPerView: 1 },
   576: { slidesPerView: 1 },
   768: { slidesPerView: 2 },
   992: { slidesPerView: 2 },
   1400: { slidesPerView: 2 },
}

const getUrl = (photoUrl) => urlBaseUploads + photoUrl

onMounted(async () => {
   if (!store.state.team || store.state.team.length === 0) {
      await fetchDirectData()
   }
})
</script>

<style scoped>
.loading-state {
   min-height: 400px;
   display: flex;
   align-items: center;
   justify-content: center;
}
</style>