<template>
   <div class="col-xl-4 col-lg-4">
      <div class="blog__sidebar">
         <div class="sidebar__widget mb-50">
            <div class="sidebar__widget-content">
               <!-- <div class="search">
                  <form action="#">
                     <input type="text" placeholder="Search...">
                     <button type="submit"><i class="far fa-search"></i></button>
                  </form>
               </div> -->
            </div>
         </div>
         <div class="sidebar__widget sidebar__widget-padding mb-75 grey-bg">
            <div class="sidebar__widget-title mb-25">
               <h4>Recent News</h4>
            </div>
            <div class="sidebar__widget-content">
               <div class="rc-post">
                  <ul>
                     <li v-for="post of blogPosts" :key="post.id" class="d-flex align-items-center mb-20">
                        <div class="rc-thumb mr-15">
                           <router-link to="/blog-details">
                              <img :src="getUrl(post.attributes.photos.data.attributes.url)"
                                 alt="rc-post">
                           </router-link>
                        </div>
                        <div class="rc-text">
                           <h6>
                              <router-link @click="handleClick(post.id)" to="/blog-details">
                                 {{ post.attributes.title }}
                              </router-link>
                           </h6>
                           <span class="rc-meta"><i class="fal fa-clock"></i>
                              {{ normalizeDateTime(post.attributes.publishedAt) }}</span>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <!-- <div class="sidebar__widget sidebar__widget-padding mb-75 grey-bg">
                        <div class="sidebar__widget-title mb-25">
                              <h4>Categories</h4>
                        </div>
                        <div class="sidebar__widget-content">
                              <div class="cat-link">
                                 <ul>
                                    <li>
                                       <router-link to="/blog-details">Web Design (6)</router-link>
                                    </li>
                                    <li>
                                       <router-link to="/blog-details"> Web Development (14)</router-link>
                                    </li>
                                    <li>
                                       <router-link to="/blog-details">Graphics (12)</router-link>
                                    </li>
                                    <li>
                                       <router-link to="/blog-details">IOS/Android Design (10)</router-link>
                                    </li>
                                    <li>
                                       <router-link to="/blog-details">App & Saas (4)</router-link>
                                    </li>
                                    <li>
                                       <router-link to="/blog-details">Fresh Products (9)</router-link>
                                    </li>
                                    <li>
                                       <router-link to="/blog-details">Saas Design (8)</router-link>
                                    </li>
                                 </ul>
                              </div>
                        </div>
                     </div> -->
         <!-- <div class="sidebar__widget sidebar__widget-padding mb-60 grey-bg">
                        <div class="sidebar__widget-title mb-25">
                              <h4>Recent Comments</h4>
                        </div>
                        <div class="sidebar__widget-content">
                              <div class="rc__comments">
                                 <ul>
                                    <li class="d-flex mb-25">
                                          <div class="rc__comments-icon mr-30">
                                             <i class="fas fa-comment"></i>
                                          </div>
                                          <div class="rc__comments-content">
                                             <h6>Justin Case</h6>
                                             <p>My lady mush hanky panky young delinquent.!</p>
                                          </div>
                                    </li>
                                    <li class="d-flex mb-25">
                                          <div class="rc__comments-icon mr-30">
                                             <i class="fas fa-comment"></i>
                                          </div>
                                          <div class="rc__comments-content">
                                             <h6>Eric Widget</h6>
                                             <p>My lady mush hanky panky young delinquent.!</p>
                                          </div>
                                    </li>
                                    <li class="d-flex">
                                          <div class="rc__comments-icon mr-30">
                                             <i class="fas fa-comment"></i>
                                          </div>
                                          <div class="rc__comments-content">
                                             <h6>Penny Tool</h6>
                                             <p>My lady mush hanky panky young delinquent.!</p>
                                          </div>
                                    </li>
                                 </ul>
                              </div>
                        </div>
                     </div> -->
         <!-- <div class="sidebar__widget sidebar__widget-padding mb-50 grey-bg">
                        <div class="sidebar__widget-title mb-15">
                              <h4>Popular Tags</h4>
                        </div>
                        <div class="sidebar__widget-content">
                              <div class="tags">
                                 <router-link to="/blog-details">The Saas,</router-link>
                                 <router-link to="/blog-details">Pix Saas Blog,</router-link>
                                 <router-link to="/blog-details">Landing,</router-link>
                                 <router-link to="/blog-details">UI/UX Design,</router-link>
                                 <router-link to="/blog-details">Branding,</router-link>
                                 <router-link to="/blog-details">Animation,</router-link>
                                 <router-link to="/blog-details">Design,</router-link>
                                 <router-link to="/blog-details">Ideas</router-link>
                              </div>
                        </div>
                  </div> -->
      </div>
   </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const blogPosts = computed(() => store.state.blogPosts).value
const isProduction = process.env.VUE_APP_environment === 'production'
const urlBaseUploads = isProduction ? process.env.VUE_APP_strapiServerProd : process.env.VUE_APP_strapiServerLocal

const handleClick = (articleId) => {
   store.commit('setSelectedArticle', articleId)
}

const normalizeDateTime = (dateTimeString) => {
   const date = new Date(dateTimeString)
   const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
   }

   return date.toLocaleDateString(undefined, options)
}

const getUrl = (photUrl) => {
   const url = urlBaseUploads + photUrl
   return url
}

</script>