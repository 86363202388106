<template>
    <Header/>
    <Breadcrumb title="Portfolio" subtitle="Portfolio" />
    <PortfolioArea/>
    <Brand border="brand-border"/>
    <QualityArea/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import PortfolioArea from '../../components/Portfolio/PortfolioArea.vue';
import Brand from '../../components/common/BrandArea.vue';
import QualityArea from '../../components/common/QualityArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
   name:'PortfolioPage',
   components:{
       Header,
       Breadcrumb,
       PortfolioArea,
       Brand,
       QualityArea,
       Footer,
   }
}
</script>