<template>
  <div :class="padd === 'true' ? 'team-area pt-120 pb-0' : 'team-area pt-120 pb-90'" style="background-color: #f1f1f1;">
    <div class="container">
      <div class="row mb-60">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>Chapter Leadership</h5>
            <h2 class="section-title">Our Patriots</h2>
          </div>
        </div>
      </div>
      <div class="">
        <div class="team-active swiper-container pb-30">
          <Swiper ref="mySwiper" class="pb-50" :space-between="25" :slides-per-view="4" :modules="modules"
            :pagination="{ clickable: true }" :loop="false" :breakpoints="breakpoints">
            <SwiperSlide v-for="member in team" :key="member.id">
              <div class="team-item swiper-slide">
                <div class="tpteam text-center mb-30">
                  <div class="tpteam__img">
                    <img :src="getUrl(member.attributes.image.data.attributes.url)" alt="" />
                    <div class="tpteam__social">
                      <router-link :to="{ name: 'team-details', params: { id: normalizeUrl(member.attributes.name) } }"> <i
                          class="fas fa-expand-arrows-alt" style="font-size: 30px;"></i></router-link>
                    </div>
                  </div>
                  <div class="tpteam__text">
                    <h3 class="tpteam-title">
                      <router-link
                        :to="{ name: 'team-details', params: { id: normalizeUrl(member.attributes.name) } }">{{
                        member.attributes.name }}</router-link>
                    </h3>
                    <h5>{{ member.attributes.title }}</h5>
                    <h6>{{ member.attributes.conflict }}</h6>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <!-- Add Pagination -->
          <div class="swiper-pagination team-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination } from "swiper"
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const isProduction = process.env.VUE_APP_environment === 'production'
const urlBaseUploads = isProduction ? process.env.VUE_APP_strapiServerProd : process.env.VUE_APP_strapiServerLocal
const padd = 'true'
const team = computed(() => store.state.team)

const breakpoints = {
  300: { slidesPerView: 1 },
  550: { slidesPerView: 2 },
  768: { slidesPerView: 4 },
  992: { slidesPerView: 5 },
}

const getUrl = (photUrl) => urlBaseUploads + photUrl
const normalizeUrl = (str) => str.replace(/\s+/g, '-').toLowerCase()

const modules = [Pagination]

</script>
