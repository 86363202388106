<template>
    <Header/>
    <Breadcrumb title="Chapter Leadership" subtitle="Team Details" />
    <TeamDetailsArea/>
    <Cta/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import TeamDetailsArea from '../../components/TeamDetails/TeamDetailsArea.vue';
import Cta from '../../components/Home/Cta.vue';
import Footer from '../../components/Home-two/HomeTwoFooter.vue'

export default {
    name:'TeamDetails',
    components:{
        Header,
        Breadcrumb,
        TeamDetailsArea,
        Cta,
        Footer,
    }
}
</script>