import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueEasyLightbox from 'vue-easy-lightbox'
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js'
import 'bootstrap/scss/bootstrap.scss'
import 'swiper/css/bundle'
import './assets/css/fontAwesome5Pro.css'
import './assets/fonts/flaticon.css'
import 'bootstrap'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './assets/scss/main.scss'
import store from './storage/globalStorage.js'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const app = createApp(App)

app.use(VueSweetalert2)
app.use(VueTippy)
app.use(store)
app.use(router)
app.use(BootstrapVue3)
app.use(VueEasyLightbox)
app.mount('#app')
