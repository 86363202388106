<template>
     <div class="contact-map">
        <div id="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98263.09034992859!2d-104.76316148518069!3d39.67847728434902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c6301e7b87f61%3A0x70c7e825a44b6b95!2s15821%20E%20Centretech%20Cir%2C%20Aurora%2C%20CO%2080011!5e0!3m2!1sen!2sus!4v1715267390049!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name:'ContactMap'
}
</script>