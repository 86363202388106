import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
    state: {
        // Define your state here
        blogPosts: [],
        selectedArticle: 0,
        team: [],
        partners: [],
        strapiUrlApi: '',
        strapiUrlUploads: ''
    },
    mutations: {
        // Define mutations to modify state
        setBlogPosts(state, posts) {
            state.blogPosts = posts
        },

        setSelectedArticle(state, articleId) {
            state.selectedArticle = articleId
        },

        setTeam(state, team) {
            state.team = team
        },

        setStrapiUrlApi(state, url) {
            state.strapiUrlApi = url
        },

        setStrapiUrlUploads(state, url) {
            state.strapiUrlUploads = url
        },

        setPartners(state, partners) {
            state.partners = partners
        }
    },
    actions: {
        // Define actions to commit mutations
    },
    modules: {
        // Optionally, split your store into modules
    },
    plugins: [createPersistedState()]
});