<script>
import { defineComponent } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import { useTippy } from 'vue-tippy'

export default defineComponent({
    components: {
        FullCalendar
    },
    props: {
        schedule: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            events: [],
        }
    },
    computed: {
        calendarOptions() {
            return {
                plugins: [dayGridPlugin],
                initialView: 'dayGridMonth',
                weekends: true,
                events: this.events,
                eventColor: '#378006',
                eventMouseEnter: this.handleEventMouseEnter
            }
        }
    },
    methods: {
        handleEventMouseEnter(data) {
            useTippy(data.el, {
                content: data.event.extendedProps.description,
            })
        },

    },
    watch: {
        schedule(newSchedule) {
            if (newSchedule && newSchedule.length > 0) {
                this.events = newSchedule.map(event => ({
                    title: event.attributes.title,
                    date: event.attributes.meeting,
                    description: event.attributes.description
                }))
            }
        }
    }
})

</script>

<template>
    <div id="calendar" class="about-area pt-120 pb-90" style="background-color: white">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12">
                    <div class="ab-box pl-50 mb-30">
                        <div class="sec-wrapper">
                            <h5>Upcoming Events</h5>
                            <h2 class="section-title">Chapter 375</h2>
                            <FullCalendar :options="calendarOptions" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
