<template>
   <section class="blog__area pt-120 pb-120">
      <div class="container">
         <div class="row">
            <div class="col-xl-8 col-lg-8">
               <div class="blog__wrapper pr-50">
                  <div class="blog-wrap blog-item mb-50">
                     <h3 class="blog-title">
                        {{ title }}
                     </h3>
                     <ul class="blog-meta" style="display: flex;  ">
                        <li style="margin-right: 18px;"><a href="#"><i class="fal fa-user-circle"></i> {{ author }}</a></li>
                        <li><a href="#"><i class="fal fa-clock"></i> {{
                           normalizeDateTime(publishedDate) }}</a></li>
                        <!-- <li><a href="#"><i class="fal fa-comments"></i> 2 Comments</a></li> -->
                     </ul>
                     <br>
                     <div class="blog-thumb">
                        <img :src="photoUrl" alt="blog">
                     </div>
                     <div class="blog-details blog-details-2">
                        <div class="blog-text">
                           <p v-for="( paragraph, index ) in  article " :key="index">{{ paragraph.children[0].text }}
                           </p>
                        </div>
                        <!-- <div class="blog-info d-sm-flex align-items-center justify-content-between mb-40">
                           <div class="blog-tag">
                              <span>Tags: </span>
                              <a href="#"> Business,</a>
                              <a href="#">Agency,</a>
                              <a href="#">Technology</a>
                           </div>
                           <div class="blog-category">
                              <span>Category: </span>
                              <a href="#"> Business</a>
                              <a href="#">Agency</a>
                           </div>
                        </div> -->
                     </div>
                  </div>
                  <!-- <div class="blog__author mb-95 d-md-flex">
                     <div class="blog__author-img mr-30">
                        <img src="assets/img/blog/author/author-1.jpg" alt="">
                     </div>
                     <div class="blog__author-content">
                        <h5>{{ author }}</h5>
                        <div class="blog__author-social">
                           <ul>
                              <li><a href="#"> <i class="fab fa-linkedin-in"></i></a></li>
                           </ul>
                        </div>
                        <p>Cole Stasney is the Junior Vice commander, and a massive dickbag</p>
                     </div>
                  </div> -->
                  <!-- post comments start -->
                  <!-- <PostComments /> -->
                  <!-- post comments end -->
                  <!-- post comment form start -->
                  <!-- <PostCommentForm /> -->
                  <!-- post comment form end -->
               </div>
            </div>
            <!-- blog right side start -->
            <BlogRightSide />
            <!-- blog right side end -->
         </div>
      </div>
   </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import BlogRightSide from '../Blog/BlogRightSide.vue'
// import PostCommentForm from './PostCommentForm.vue'
// import PostComments from './PostComments.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const blogPosts = computed(() => store.state.blogPosts);
const selectedArticle = computed(() => store.state.selectedArticle);
const post = computed(() => blogPosts.value.find(post => post.id === selectedArticle.value))
// const localEnv = process.env.VUE_APP_environment
const isProduction = process.env.VUE_APP_environment === 'production'
const urlBaseUploads = isProduction ? process.env.VUE_APP_strapiServerProd : process.env.VUE_APP_strapiServerLocal

// Extract data from the post
let author
let article
let title
let publishedDate
let photoUrl
// let photoUrl2

try {
   author = computed(() => post.value.attributes.name)
   article = computed(() => post.value.attributes.post)
   title = computed(() => post.value.attributes.title)
   publishedDate = computed(() => post.value.attributes.date)
   photoUrl = computed(() => `${urlBaseUploads}${post.value.attributes.photos.data.attributes.url}`)
   // photoUrl2 = computed(() => `'/api'${post.value.attributes.photos.data.attributes.url}`)
   console.log(post.value.attributes)
}
catch (error) {
   router.push('/')
}

const normalizeDateTime = (dateTimeString) => {
   const date = new Date(dateTimeString)
   const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
   }

   return date.toLocaleDateString(undefined, options)
}

</script>