<template>
    <Header/>
    <Breadcrumb title="Contact" subtitle="Contact" />
    <ContactArea/>
    <ContactMap/>
    <Brand border="brand-border" />
    <QualityArea/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
// import Breadcrumb from '../../components/common/Breadcrumb.vue';
import ContactArea from '../../components/Contact/ContactArea.vue';
import ContactMap from '../../components/Contact/ContactMap.vue';
// import Brand from '../../components/common/BrandArea.vue';
// import QualityArea from '../../components/common/QualityArea.vue';
import Footer from '../../components/Home-two/HomeTwoFooter.vue'

export default {
    name:'ContactPage',
    components:{
        Header,
        // Breadcrumb,
        ContactArea,
        ContactMap,
        // Brand,
        // QualityArea,
        Footer,
    }
}
</script>