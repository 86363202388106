<template>
    <footer :style="{backgroundImage:'url('+ require('../../assets/img/bg/footer-bg.jpg') + ')'}" class="footer-2 pt-95 position-relative">
            <div class="common-shape-wrapper">
               <div class="common-shape-inner"></div>
            </div>
            <div class="footer-area pb-60">
               <div class="container">
                  <div class="row">
                        <div class="col-lg-4 col-sm-6">
                           <div class="widget mb-30">
                              <div class="footer-logo">
                                    <router-link to="/">
                                       <img src="../../assets/img/logo/logoMOPH.png" class="img-fluid" alt="footer-logo">
                                    </router-link>
                              </div>
                              <p class="mb-5 pr-35">Chapter 375 of the Military Order of the Purple Heart, USA.</p>
                              <!-- <div class="footer-social footer-social-2">
                                    <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-facebook"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-pinterest-p"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                              </div> -->
                           </div>
                        </div>
                        <div class="col-lg-2 col-sm-6">
                           <div class="widget mb-30">
                              <h4 class="widget-title mb-35">Links</h4>
                              <ul>
                                    <li><router-link to="/contact">Contact</router-link></li>
                              </ul>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                           <div class="widget widget-contact mb-30">
                              <h4 class="widget-title mb-35">Contact</h4>
                              <ul>
                                    <li class="pb-10"><a target="_blank" href="https://goo.gl/maps/tbeTZK7zB6MpcBgn9" >15821 E Centretech Cir, Aurora, CO 80011</a></li>
                                    <li><i class="fal fa-envelope-open"></i><a href="mailto:gatortogator@aol.com">gatortogator@aol.com</a></li>
                                    <li><i class="fal fa-phone-alt"></i><a href="tel:17202068353">(720) 206-8353</a></li>
                              </ul>
                           </div>
                        </div>
                        <!-- <div class="col-lg-3 col-sm-6">
                           <div class="widget mb-30">
                              <h4 class="widget-title mb-30">Newsletter</h4>
                              <p class="mb-20">Subscribe to Our Newsletter for Updates</p>
                              <div class="widget-newsletter">
                                    <form action="#">
                                       <input type="email" placeholder="Email Address">
                                       <button type="submit">Send</button>
                                    </form>
                              </div>
                           </div>
                        </div> -->
                  </div>
               </div>
            </div>
            <!-- <div class="copyright-area">
               <div class="container">
                  <div class="copyright-bg">
                        <div class="row align-items-center">
                           <div class="col-md-6">
                              <div class="copyright">
                                    <span>Copyright ©2022 Themepure. All Rights Reserved</span>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="privacy-text text-md-end"> 
                                    <ul>
                                       <li>
                                          <router-link to="/contact">Terms & Condition</router-link>
                                          <router-link to="/contact">Privacy Policy</router-link>
                                       </li>
                                    </ul>
                              </div>
                           </div>
                        </div>
                  </div>
               </div>
            </div> -->
         </footer>
</template>

<script>
export default {
    name:'HomeTwoFooter',
}
</script>