<template>
  <section id="partners" class="review-area review-area-padding pos-rel pl-50 pr-50 grey-bg pt-120 pb-90">
    <div class="container">
      <div class="row mb-40 z-index">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>Community Relationships</h5>
            <h2 class="section-title">Our Partners</h2>
          </div>
        </div>
      </div>
      <div class="test-active swiper-container">
        <swiper ref="mySwiper" class="swiper-wrapper pb-70" :space-between="25" :slides-per-view="3" :modules="modules"
          :pagination="{ clickable: true }" :loop="false" :breakpoints="breakpoints">
          <swiper-slide v-for="partner in partners" :key="partner.id">
            <div class="testi-item swiper-slide">
              <div class="tptestinimail__author--img">
                <img :id="partner.id" :src="getUrl(partner.attributes.logo.data.attributes.url)" alt=""
                  v-on:mouseover="handleEventMouseEnter($event, partner.attributes.name)" />
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination } from "swiper"
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useTippy } from 'vue-tippy'

const store = useStore()
const modules = [Pagination]

const partners = computed(() => store.state.partners)
// const localEnv = process.env.VUE_APP_environment
const isProduction = process.env.VUE_APP_environment === 'production'
const urlBaseUploads = isProduction ? process.env.VUE_APP_strapiServerProd : process.env.VUE_APP_strapiServerLocal

const breakpoints = {
  0: { slidesPerView: 1 },
  576: { slidesPerView: 1 },
  768: { slidesPerView: 2 },
  992: { slidesPerView: 3 },
  1400: { slidesPerView: 3 },
}

const getUrl = (photUrl) => urlBaseUploads + photUrl

const handleEventMouseEnter = (event, partner) => {
  const element = event.target

  useTippy(element, {
    content: partner,
  })
}

</script>
